<template>
  <div>
    <p class="text-2xl mb-6">Libro Comandas</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="cols">
            <dos-fechas
              :fechas="[filtros.fecha_desde, filtros.fecha_hasta]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-expand-transition>
              <div v-show="show">
                <strong class="mb-2">Filtros</strong>
                <v-row>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Mesoneros'" v-model="filtros.id_mesonero" />
                  </v-col>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Cajero'" v-model="filtros.id_cajero" />
                  </v-col>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Comandero'" v-model="filtros.id_comandero" />
                  </v-col>
                  <v-col cols="4">
                    <BuscarCajaVue v-model="filtros.id_caja" />
                  </v-col>
                  <v-col cols="4">
                    <EstadoSelect
                      :clearable="true"
                      :label="'Estado Pago'"
                      :dense="true"
                      :outlined="true"
                      :add="false"
                      :padre="3"
                      v-model="filtros.id_estado_pago"
                    />
                  </v-col>

                  <v-col cols="4">
                    <EstadoSelect
                      :clearable="true"
                      :label="'Estado Despacho'"
                      :dense="true"
                      :outlined="true"
                      :add="false"
                      :padre="4"
                      v-model="filtros.id_estado_despacho"
                    />
                  </v-col>

                  <v-col cols="4">
                    <EstadoSelect
                      :clearable="true"
                      :label="'Estado Fiscal'"
                      :dense="true"
                      :outlined="true"
                      :add="false"
                      :padre="12"
                      v-model="filtros.id_estado_fiscalizado"
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-row class="mt-1">
                      <v-select
                        clearable
                        v-model="filtros.filt_cantidad"
                        :items="filterTotales"
                        hide-details
                        dense
                        outlined
                        label="Cantidad Tipo"
                      ></v-select>
                      <v-text-field
                        clearable
                        v-model="filtros.filt_cantidad_desde"
                        hide-details
                        label="Desde"
                        dense
                        outlined
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        clearable
                        v-model="filtros.filt_cantidad_hasta"
                        hide-details
                        label="Hasta"
                        dense
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="GetVer"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
          :fontSize="4"
          :acciones="['VER']"
          :height="600"
        ></tabla-reporte>
      </v-card-text>
    </v-card>
    <modal-ver-comanda-vue
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    >
    </modal-ver-comanda-vue>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'
import TablaReporte from '@/components/TablaSimpleReporte.vue'
import { ref } from '@vue/composition-api'
import SelectPuesto from '../../../archivo/puesto/components/SelectPuesto.vue'
import MaestroService from '@/api/servicios/MaestroService'
import moment from 'moment'
import store from '@/store'
import DatePicher from '@/components/DatePicker.vue'
import ComandaService from '@/api/servicios/ComandaService'
import DosFechas from '@/components/DosFechas.vue'
import BuscarEmpleadoId from '../../../archivo/empleado/components/BuscarEmpleadoId.vue'
import BuscarCajaVue from '../../../ventas/caja/componentes/BuscarCaja.vue'

import EstadoSelect from '../../../archivo/estado/componentes/Select.vue'
import ModalVerComandaVue from '../../../dashboard/componentes/ModalVerComanda.vue'
// demos

export default {
  components: {
    TablaReporte,
    DatePicher,
    SelectPuesto,
    DosFechas,
    BuscarEmpleadoId,
    BuscarCajaVue,
    EstadoSelect,
    ModalVerComandaVue,
  },
  setup() {
    const nombreReporte = ref(`Comadas por producto `)
    const show = ref(false)
    const filterTotales = ['Total', 'SubTotal', 'Servicio', 'IVA', 'Pago', 'IGTF']
    const openModalComanda = ref(false)
    const comandaSelect = ref(null)

    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const cabeceraExcel = ref([
      {
        puesto: 'Locatario/Area',
        desde: 'Desde',
        hasta: 'Hasta',
        nombre: 'Producto',
        cant: 'Cant',
        total: 'Total',
      },
    ])

    const cabecera = ref([
      { text: 'Fecha', value: 'fecha', format: { small: true } },
      { text: 'Hora', value: 'hora', format: { small: true } },
      { text: 'Comanda', value: 'id', format: { small: true } },
      { text: 'Numeros Comensales', value: 'numero_comensales', format: { small: true } },
      { text: 'Descripcion', value: 'cliente', format: { small: true } },
      { text: 'Caja', value: 'nombre_caja', format: { small: true } },
      { text: 'Cajero', value: 'nombre_cajero', format: { small: true } },
      { text: 'Mesonero', value: 'nombre_mesonero', format: { small: true } },
      { text: 'Comandero', value: 'nombre_comandero', format: { small: true } },
      { text: 'Cliente', value: 'nombre_cliente', format: { small: true } },
      { text: 'Mesa', value: 'nombre_mesa', format: { small: true } },
      { text: 'Items', value: 'items', format: { small: true } },
      { text: 'Items Anulados', value: 'items_anulados', format: { small: true } },
      { text: 'Sub Total', value: 'subtotal', format: { small: true } },
      { text: 'Descuento', value: 'descuento', format: { small: true } },
      { text: 'IVA', value: 'iva', format: { small: true } },
      { text: 'Servicio', value: 'comision', format: { small: true } },
      { text: 'IGTF', value: 'igtf', format: { small: true } },
      { text: 'Total', value: 'total', format: { small: true } },
      { text: 'Total Pago', value: 'total_pago', format: { small: true } },
      { text: 'Total por Cobrar', value: 'total_por_cobrar', format: { small: true } },
      { text: 'Estado pago', value: 'descripcion_estado_pago', format: { small: true } },
      { text: 'Anulado por', value: 'nombre_anula', format: { small: true } },
      { text: 'Fecha anulacion', value: 'fecha_anulacion', format: { small: true } },
      { text: 'Motivo anulacion', value: 'motivo_anulacion', format: { small: true } },
      { text: 'IVA Exonerado', value: 'ind_exonerar_iva', format: { small: true } },
      { text: 'Estado Despacho', value: 'descripcion_estado_despacho', format: { small: true } },
      { text: 'Estado Fiscalizado', value: 'descripcion_estado_fiscalizado', format: { small: true } },
      { text: 'Factura No', value: 'numero_control', format: { small: true } },
      { text: 'Serial Impresora', value: 'serial_impresora', format: { small: true } },
    ])
    const datos = ref([])
    const fechas = ref(null)
    const cargando = ref(false)
    const puesto = ref({})
    const filtros = ref({
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: moment().format('YYYY-MM-DD'),
      id_mesonero: null,
      id_cajero: null,
      id_comandero: null,
      id_caja: null,
      id_estado_pago: null,
      id_estado_despacho: null,
      id_estado_fiscalizado: null,
      filt_cantidad: null,
      filt_cantidad_desde: null,
      filt_cantidad_hasta: null,
    })
    const validar = () => {
      let validado = true
      if (puesto.value.id == undefined) {
        validado = false
      }
      if (fechas.value == undefined) {
        validado = false
      }

      return validado
    }
    const GetVer = item => {
      console.log(item.id)
      comandaSelect.value = { id: item.id }
      openModalComanda.value = true
    }
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha_desde = datos.desde
      filtros.value.fecha_hasta = datos.hasta
    }
    const buscarDatos = () => {
      cargando.value = true
      const params = JSON.parse(JSON.stringify(filtros.value))
      if (params.filt_cantidad) {
        params.filt_cantidad = params.filt_cantidad.toLowerCase()
      }
      MaestroService.ReporteLibroComandas(params)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos.comandas
            nombreReporte.value = `Libro comandas  ${filtros.value.fecha_desde}  al ${filtros.value.fecha_hasta}`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const getPuesto = datos => {
      puesto.value = datos
    }
    return {
      cabecera,
      datos,
      getPuesto,
      fechas,
      puesto,
      validar,
      buscarDatos,
      cargando,
      cabeceraExcel,
      nombreReporte,
      filtros,
      getFechas,
      show,
      filterTotales,
      GetVer,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
      },
      openModalComanda,
      comandaSelect,
      GetOpenModalComanda,
    }
  },
}
</script>
